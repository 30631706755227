.App {
	font-family: sans-serif;
  }
  
  ul {
	margin: 0;
  }
  
  .dropzone {
	text-align: center;
	padding: 20px;
	margin-top: 16px;
	border: 3px dashed #eeeeee;
	background-color: #fafafa;
	color: #bdbdbd;
	cursor: pointer;
	margin-bottom: 20px;
  }
  
  .accept {
	border-color: #107c10 !important;
  }
  
  .reject {
	border-color: #d83b01 !important;
  }
  