@import url(https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic);
*{box-sizing:border-box;margin:0;padding:0}html{height:100%;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body{background-color:#f4f6f8;height:100%}a{text-decoration:none}#root{height:100%}

@font-face {
    font-family: 'Battambang-Regular';
    src: local('Battambang-Regular'), url(/static/media/Battambang-Regular.b5e24ee9.ttf) format('truetype');
}
body {
    font-family: "Calibri", "Battambang-Regular";
}
.App {
	font-family: sans-serif;
  }
  
  ul {
	margin: 0;
  }
  
  .dropzone {
	text-align: center;
	padding: 20px;
	margin-top: 16px;
	border: 3px dashed #eeeeee;
	background-color: #fafafa;
	color: #bdbdbd;
	cursor: pointer;
	margin-bottom: 20px;
  }
  
  .accept {
	border-color: #107c10 !important;
  }
  
  .reject {
	border-color: #d83b01 !important;
  }
  
.App {
	font-family: sans-serif;
  }
  
  ul {
	margin: 0;
  }
  
  .dropzone {
	text-align: center;
	padding: 20px;
	margin-top: 16px;
	border: 3px dashed #eeeeee;
	background-color: #fafafa;
	color: #bdbdbd;
	cursor: pointer;
	margin-bottom: 20px;
  }
  
  .accept {
	border-color: #107c10 !important;
  }
  
  .reject {
	border-color: #d83b01 !important;
  }
  
